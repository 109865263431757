// import React from 'react';

import {
  boxUploader,
  uploadFile,
  voiceRequest,
  voisparkMain,
} from "../img/projImages.js";

const voisparkProject = {
  title: "VS3 (Voice Spark)",
  shortTitle: "vs3",
  description: "An internal voice-over file request & management tool.",
  heroImage: voiceRequest,
  heroAlt: "Screencap of voice request process.",
  previewImg: voisparkMain,
  previewAlt: "Screencap of Writer Dashboard on Voice Spark.",
  skills: ["HTML5", "CSS3", "JS/ES6", "Figma", "PHP", "MySQL"],
  introText: [
    {
      index: 0,
      text: "Voice Spark, otherwise known as VS3, is an internal tool used for requesting, previewing, and managing voice-over files. Voice Spark was built to replace a depreciated system, and since launching has successfully handled over 1,000 requests, while managing an overall database of 10,000+ requests.",
    },
  ],
  imgsParas: [
    {
      index: 0,
      img: uploadFile,
      alt: "Screencap of process to upload a single script file on Voice Spark.",
      text: "Three types of users access Voice Spark: admins, writers, and voice talents. Writers can make requests, send scripts, and manage due dates and corrections. Voice Talents review requests, update their progress, and upload voice files.",
    },
    {
      index: 1,
      img: boxUploader,
      alt: "Screencap of use of Box uploader UI in Voice Spark.",
      text: "Previously, one of the slowest processes for writers and voice talents involved uploading and managing files, so we prioritized the automation of these tasks through VS3. The system integrates WorkFront and Box API for efficient, automated project and file management. Native Box UI is embedded in the system so users can easily upload, preview, and download files relevant only to their requests.",
    },
    {
      index: 2,
      img: voisparkMain,
      alt: "Screencap of Writer Dashboard on Voice Spark.",
      text: "All users have a dashboard to manage requests, but because privacy is important to both clients and voice talents, each dashboard filters requests based on user permission and assignments. For timely responses, all users receive email notifications upon request status changes.",
    },
  ],
  optstyles: "dropshadow",
};

export default voisparkProject;
