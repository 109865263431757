// import React from 'react';
import {
  firstSecondImg,
  finalClickImg,
  menuSelectImg,
  menuScrollImg,
  previewImg,
} from "../img/projImages.js";

const ScrollingInfoProject = {
  title: "Scrolling Infographic",
  shortTitle: "scrollinfo",
  description: "An interactive infographic on goal-setting steps.",
  heroImage: firstSecondImg,
  heroAlt:
    "Screencap of an infographic, clicking on an arrow. New content animates on.",
  previewImg: previewImg,
  previewAlt:
    'Beginning frame of an infographic, with title text "See an Effective Goal in Action"',
  skills: ["HTML5", "CSS3", "JS/ES6", "AdobeXD"],
  introText: [
    {
      index: 0,
      text: "This piece started as a static infographic, offered to customers as part of an employee performance content package and focused on goal-setting. We kept the formatting and simplistic branding, and added dynamic interaction, with the primary goal of allowing the user to focus on each individual section.",
    },
  ],
  imgsParas: [
    {
      index: 0,
      img: finalClickImg,
      alt: "Screencap of an infographic, clicking on an arrow and final piece of the content animates in.",
      text: "Our primary focus in making this asset interactive was to truly break down the goal-setting steps into bite-size pieces. So instead of overwhelming the user with all the text content at once, the interactive version allows them to move along a path and see each section on its own. The arrow invites them to click to move on when they are ready.",
    },
    {
      index: 1,
      img: menuScrollImg,
      alt: "Screencap of an infographic, clicking to open a side navigation window and scrolling between sections of the infographic.",
      text: "The side menu provides another option for navigating through the content. Users can scroll to the new content and see the section highlighted in the menu.",
    },
    {
      index: 2,
      img: menuSelectImg,
      alt: "Screencap of an infographic, using the side navigation window to jump to a specific section of the infographic.",
      text: "Because goal-setting is a dynamic process, we wanted to allow users to jump between steps in a non-linear fashion. The side menu allows the user toclick on a section's title to go directly to the portion they want to focus on.",
    },
  ],
  optstyles: "dropshadow",
};

export default ScrollingInfoProject;
