// import React from 'react';

//image size should be 1200 × 661

import {
  timelineCalStill,
  firstTwo,
  hardSkills,
  timelineCal,
  skipAhead,
} from "../img/projImages.js";

const InfoExpProject = {
  title: "Goal-Setting Experience",
  shortTitle: "infoexp",
  description:
    "An interactive experience fusing education and personal goal-setting.",
  heroImage: firstTwo,
  heroAlt:
    "Screencap gif of a webpage that states Oh hey there! Let's talk about goals.' with an arrow button below. The mouse clicks on the arrow, leading to another page animating on.",
  previewImg: timelineCalStill,
  previewAlt:
    "Screencap of an infographic showing a timeline for goal-setting.",
  skills: ["HTML5", "CSS3", "JS/ES6", "AdobeXD"],
  introText: [
    {
      index: 0,
      text: "Building on the goal-setting steps outlined in a pre-existing infographic, my team created this piece as a more immersive, personalized approach to teaching goal-setting. We started with a basic wireframe, then partnered with a member of the Art team to brand the experience.",
    },
  ],
  imgsParas: [
    {
      index: 0,
      img: hardSkills,
      alt: "Screencap gif of a webpage, with a user navigating to a screen that directs them to identify two hard skills. The user rolls over the phrase 'hard skills' for a definition, then fills in two hard skills in the input boxes.",
      text: "This interactive piece breaks down goal-setting into small parts, including identifying hard and soft skills needed to reach professional goals. For this section, we thought about and included two resources a user would need to identify their hard skills: the definition of hard skills and Suggested Skills. To keep the user on track, we also display a preview of their overall goal.",
    },
    {
      index: 1,
      img: timelineCal,
      alt: "Screencap gif of a webpage with the header 'Congrats, you have a plan!' and with an animated timeline. Mouse clicks to the next page, with the headline 'Set a couple of dates to check in on your goal' and a calendar.",
      text: "Because deadlines are so important in setting achievable goals, we wanted to visually highlight check-in dates in two different ways. First, the user sees a simple timeline, leading them from the present to their projected deadline for reaching their goal. Next, the custom-created calendar provides a clear image of where their deadlines and check-ins can fit into the upcoming months.",
    },
    {
      index: 2,
      img: skipAhead,
      alt: "Screencap gif of a webpage with a navigation bar at the bottom. Mouse clicks on the first grey checkmark, which turns orange and jumps the page to another section. This action is repeated.",
      text: "Setting goals can be an intimidating process, but this piece is designed to break down the steps for the user. The navigation bar at the bottom lets the user know how far they have come in the process, while also splitting the goal-setting experience into distinct sections. The markers for these sections can also be used to skip forward or back in the process.",
    },
  ],
};

export default InfoExpProject;
