//image size should be 1200 × 661
//test comment

//email templates
export const leftAlign =
  "https://imagizer.imageshack.com/img922/6498/3acf5D.png";
export const border = "https://imagizer.imageshack.com/img924/8722/7Fhv2E.png";
export const centered =
  "https://imagizer.imageshack.com/img923/9704/p6gXsF.png";
export const topBanner =
  "https://imagizer.imageshack.com/img922/5938/Ds8jEb.png";

//infographic experience
export const timelineCalStill =
  "https://imagizer.imageshack.com/img924/8218/jddzqX.png";
export const firstTwo =
  "https://imagizer.imageshack.com/img923/4145/0iOD2a.gif";
export const hardSkills =
  "https://imagizer.imageshack.com/img922/737/V3qCKH.gif";
export const timelineCal =
  "https://imagizer.imageshack.com/img922/9417/niW7cT.gif";
export const skipAhead =
  "https://imagizer.imageshack.com/img923/5594/xzraMi.gif";

//motion reel
export const reelPreview =
  "https://imagizer.imageshack.com/img922/8628/HJUZkJ.png";
export const keysInTheCar =
  "https://imagizer.imageshack.com/img924/5038/0havMU.png";
export const mrSquare = "https://imagizer.imageshack.com/img924/340/UHehYw.png";
export const timeOff = "https://imagizer.imageshack.com/img923/1721/kxTIsc.png";
export const goalsTwo =
  "https://imagizer.imageshack.com/img922/8346/GWU0C5.png";

//scrolling infographic
export const firstSecondImg =
  "https://imagizer.imageshack.com/img924/3185/EKC5oy.gif";
export const finalClickImg =
  "https://imagizer.imageshack.com/img922/8760/aqHxYG.gif";
export const menuSelectImg =
  "https://imagizer.imageshack.com/img923/831/dagsHi.gif";
export const menuScrollImg =
  "https://imagizer.imageshack.com/img924/6739/6pZTzW.gif";
export const previewImg =
  "https://imagizer.imageshack.com/img922/3637/BJk7RU.png";

//the Center
export const dogPage = "https://imagizer.imageshack.com/img922/5496/o4o0mU.png";
export const indivPage =
  "https://imagizer.imageshack.com/img923/1543/IZdsP3.png";

//voispark
export const boxUploader =
  "https://imagizer.imageshack.com/img922/4823/cvktdJ.gif";
export const uploadFile =
  "https://imagizer.imageshack.com/img924/742/ygab8A.gif";
export const voiceRequest =
  "https://imagizer.imageshack.com/img922/659/ib0ja4.gif";
export const voisparkMain =
  "https://imagizer.imageshack.com/img922/7515/3kYKxn.png";

//mSE
export const addScriptBtn =
  "https://imagizer.imageshack.com/img924/3574/HdexZ0.gif";
export const navigateTabs =
  "https://imagizer.imageshack.com/img922/573/bDhqSZ.gif";
export const preBuiltTool =
  "https://imagizer.imageshack.com/img923/4055/pku9pe.gif";
export const mSEpreview = "https://i.ibb.co/4FMBXsf/m-SEpreview.png";
export const docking = "https://imagizer.imageshack.com/img924/8738/ree4qB.png";

//portals app
export const portalsImg =
  "https://imagizer.imageshack.com/img924/7326/soN2W3.png";
