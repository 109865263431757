// import React from 'react';

import { portalsImg } from "../img/projImages";

const TivianPortals = {
  title: "Tivian Portals App",
  shortTitle: "portals",
  description:
    "A customizable application for Tivian customers to gather feedback from employees and customers.",
  heroImage: portalsImg,
  heroAlt: "Preview images of the application on a Mac and mobile phone.",
  previewImg: portalsImg,
  previewAlt: "Preview images of the application on a Mac and mobile phone.",
  skills: ["HTML5", "CSS3"],
  introText: [
    {
      index: 0,
      text: "This customer-facing applicaton recently got a style refresh, and I contributed both the HTML and CSS. We used Cordova as the wrapper, and Bootstrap for the styling framework. This project involved working with Art Directors and the Engineering team.",
    },
  ],
  imgsParas: [],
  optstyles: "dropshadow",
  extLink: "https://play.google.com/store/apps/details?id=com.tivian.portals",
};

export default TivianPortals;
